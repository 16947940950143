<template>
  <t-page>
    <h3 slot="header">
      {{ $t('pages.account.security.heading') }}
    </h3>

    <t-grid>
      <t-grid-row>
        <t-grid-cell>
          <FormCardChangePassword />
        </t-grid-cell>
      </t-grid-row>
    </t-grid>
  </t-page>
</template>
<script>
import Vue from 'vue';
import FormCardChangePassword from '~/pages/account/partials/FormCardChangePassword.vue';

export default Vue.extend({
  components: {
    FormCardChangePassword,
  },
});
</script>
